<template>
  <div class="default-reservation-cancel-modal">
    <b-modal v-on="$listeners" v-bind="$props" v-model="valueComputed" size="lg" hide-header-close
             header-class="justify-content-center">

      <template #modal-title>
        <h1>{{ $t('transfers.cancel_this_transfer') }}</h1>
      </template>

      <p>{{ $t("transfers.cancel_transfer_text") }}</p>
      <b-row>
        <b-col v-for="column in columns">
          <b-button :href="column.href" variant="default">
            <font-awesome-icon v-if="column.icon" :icon="column.icon"/>
            {{ $t(column.label) }}
          </b-button>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('button.back') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
export default {
  name: "DefaultReservationCancelModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      columns: [
        //{
        //  format: 'tel',
        //  label: 'prologistics.phone',
        //  icon: 'phone'
        //},
        {
          label: 'E-Mail: ' + this.$t('prologistics.mail'),
          href: 'mailto:' + this.$t('prologistics.mail')
        }
      ]
    }
  },
  watch: {},
  computed: {
    valueComputed: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>